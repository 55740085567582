.loaderContainer {
  .loader,
  .loader::before,
  .loader::after {
    color: #000000;
    content: "";
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    animation-fill-mode: both;
    animation: loading 1.6s infinite ease-in-out;
  }
  .loader {
    position: relative;
    margin: 1em auto;
    color: red;
    font-size: 1rem;
    // transform: translateZ(0);
    animation-delay: -0.16s;
    transform: translateY(-200%);
    &::before {
      left: -3em;
      animation-delay: -0.32s;
    }
    &:after {
      left: 3em;
    }
  }
}

@-webkit-keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes loading {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
