@import "../../assets/styles/main.module";

.animate {
  transition: 0.3s;
}
.content {
  & > * {
    text-align: center;
  }
  &.vibes {
    h1 {
      font-family: "GreatVibes";
    }
  }
}

.hide {
  opacity: 0;
  transition: 0.3s;
  transform: translateY(2rem);
}
