// @import "../../../assets/styles/main.module";
.bg {
  position: relative;
  background-color: #ffffff;
  // transition: .5s;
  & .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    align-items: center;
  }
}
