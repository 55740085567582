@font-face {
  font-family: "GreatVibes";
  font-weight: normal;
  font-style: normal;
  src: url("./assets/fonts/GreatVibes-Regular.eot") format("eot"),
    url("./assets/fonts/GreatVibes-Regular.otf") format("opentype"),
    url("./assets/fonts/GreatVibes-Regular.ttf") format("truetype");
}

body {
  background: #f5f5f5;
  margin: 0;
  font-size: 14px;
  // color: #36363d;
  // color: #97a7ae;
  // color: #95a0a6;
  color: #8e9985;
  color: #949c8f;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
  @media screen and (min-width: 991px) {
    font-size: 18px;
  }
  @media screen and (min-width: 1199px) {
    font-size: 20px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
